import {
  Button,
  Modal,
  ModalContent,
  ModalOverlay,
  useMediaQuery,
} from '@cashiaApp/web-components';
import React, {useCallback, useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as GreenMoneyIcon} from '../../assets/icons/greenMoney.svg';
import {ReactComponent as RedMoneyIcon} from '../../assets/icons/redMoney.svg';
import {BankLogo} from '../../components/common/BankLogo';
import InputErrorMessage from '../../components/common/InputErrorMessage';
import {
  GetBusinessOnboardingQuery,
  useGetBusinessOnboardingQuery,
} from '../../graphql/generated';
import {isOutOfRange} from '../../utils/helper/validation';
import {usePayoutOperations} from '../../utils/mocks/resolvers/payouts';
import {cn} from '../../utils/reusableFunctions';
import {useUserAuth} from '../../utils/user';
import FormInput from '../links/Components/FormInput';

const cardItems = [
  {
    icon: <GreenMoneyIcon />,
    title: 'Total balance',
    amount: 'KES 1000.00',
  },
  {
    icon: <RedMoneyIcon />,
    title: 'Available to payout',
    amount: 'KES 1000.00',
  },
];

type PayoutFormContentProps = {
  bankName?: string;
  data: GetBusinessOnboardingQuery | undefined;
  payoutAmount: string;
  handleAmountChange: (value: string) => void;
  handlePayoutRequest: () => Promise<void>;
  isValidAmount: boolean;
  isLoading: boolean;
  errors: {[key: string]: string};
};

const PayoutFormContent = React.memo(
  ({
    bankName,
    data,
    payoutAmount,
    handleAmountChange,
    handlePayoutRequest,
    isValidAmount,
    isLoading,
    errors,
  }: PayoutFormContentProps) => {
    const renderError = (fieldName: string): JSX.Element | null => {
      if (errors[fieldName]) {
        return <InputErrorMessage errorMessage={errors[fieldName]} />;
      }
      return null;
    };
    return (
      <div className="flex flex-col h-full w-full">
        <div className="pt-8 mb-4">
          <div className="flex items-center gap-3">
            <h2 className="text-2xl text-black font-semibold">Payout to...</h2>
          </div>
        </div>

        <div className="rounded-lg p-4 border border-dividerGrey flex items-center gap-3 mb-6">
          <div className="w-12 h-12 border rounded-lg flex-shrink-0">
            <BankLogo
              bankName={data?.businessOnboarding?.bankAccountBank?.name}
              className="w-full h-full "
            />
          </div>
          <div>
            <div className="text-foggy">{bankName || ''}</div>
            <div className="font-medium text-black">
              {data?.businessOnboarding?.bankAccountNumber}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2 mb-6">
          <div className="text-foggy">Available to payout</div>
          <div className="text-xl text-black font-medium">
            <span className="font-semibold">KES</span> 290,000.00
          </div>
        </div>

        <div className="h-px bg-dividerGrey mb-6" />
        <div className="flex-grow">
          <div className="text-xl text-black font-semibold mb-2">
            Payout amount
          </div>
          <div className="text-darkGrey text-sm mb-2">
            Minimum amount is KES 100
          </div>
          <div>
            <FormInput
              name="amount"
              placeholder="0.00"
              onChange={handleAmountChange}
              value={payoutAmount}
              leftElement={
                <div>
                  <p className="font-[600]">KES</p>
                </div>
              }
              width="100%"
              className="w-full rounded-lg"
              error={renderError('amount')}
            />
          </div>
          <div className="mt-auto pt-6">
            <Button
              onClick={handlePayoutRequest}
              disabled={!isValidAmount || isLoading}
              className={cn(
                'w-full h-[48px] py-4 rounded-lg text-white text-center font-medium',
                isValidAmount ? 'bg-smoothRed' : 'bg-greyish'
              )}>
              {isLoading ? 'Processing...' : 'Continue'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
);

PayoutFormContent.displayName = 'PayoutFormContent';

const Payouts = () => {
  const {business} = useUserAuth();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [isPayoutFormOpen, setIsPayoutFormOpen] = useState(false);
  const [payoutAmount, setPayoutAmount] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errors, setErrors] = useState<{[key: string]: string}>({});
  const {data} = useGetBusinessOnboardingQuery();
  const {requestPayout, isLoading} = usePayoutOperations();
  const bankName = data?.businessOnboarding?.bankAccountBank?.name;

  const handleModalStateChange = (open: boolean) => {
    if (!business) {
      navigate('/home/self-onboarding/business-info');
      return;
    }
    if (!open) {
      setPayoutAmount('');
      setErrors({});
    }
    if (isMobile) {
      setIsPayoutFormOpen(open);
    } else {
      setIsModalOpen(open);
    }
  };

  const availableBalance =
    cardItems.find((item) => item.title === 'Available to payout')?.amount ||
    'KES 0.00';
  const numericBalance = Number(availableBalance.replace(/[^0-9.]/g, ''));

  const handleAmountChange = useCallback((value: string) => {
    if (/^\d*$/.test(value.replace(/,/g, ''))) {
      const formattedValue = value
        ? new Intl.NumberFormat('en-US').format(Number(value.replace(/,/g, '')))
        : '';

      setErrors((prevErrors) => {
        const newErrors = {...prevErrors};
        if (isOutOfRange(formattedValue, 100, 290000)) {
          newErrors.amount = 'Amount must be between 100 and 290,000 KES';
        } else {
          delete newErrors.amount;
        }
        return newErrors;
      });

      setPayoutAmount(formattedValue);
    }
  }, []);

  const handlePayoutRequest = useCallback(async () => {
    if (!payoutAmount || Object.keys(errors).length > 0) return;
    const amount = Number(payoutAmount.replace(/,/g, ''));
    await requestPayout(amount);
  }, [payoutAmount, errors, requestPayout]);

  const isValidAmount = useMemo(
    () => Boolean(payoutAmount && !errors.amount),
    [payoutAmount, errors]
  );

  const formProps = useMemo(
    () => ({
      bankName,
      data,
      payoutAmount,
      handleAmountChange,
      handlePayoutRequest,
      isValidAmount,
      isLoading,
      errors,
    }),
    [
      bankName,
      data,
      payoutAmount,
      handleAmountChange,
      handlePayoutRequest,
      isValidAmount,
      isLoading,
      errors,
    ]
  );

  const MainContent = () => (
    <>
      <h1 className="font-bold text-black text-xl mt-4 md:text-3xl">Payouts</h1>
      <p className="mt-2 mb-3 md:mb-6 font-medium">
        Here is your most recent account summary!
      </p>

      <div className="flex gap-4 md:gap-10">
        {cardItems.map((item) => (
          <div
            key={item?.title}
            className="md:w-[40%] border w-[167px] p-3 border-dividerGrey md:gap-3 gap-2 rounded-lg md:p-5 flex flex-col justify-center h-[78px] md:h-[152px]">
            <div className="flex items-center gap-3">
              <p className="hidden md:block">{item?.icon}</p>
              <h1 className="md:text-base text-xs text-foggy font-medium">
                {item?.title}
              </h1>
            </div>
            <p className="font-bold text-sm md:text-3xl">{item?.amount}</p>
          </div>
        ))}
      </div>

      <div className="py-6">
        {business ? (
          <>
            <p className="font-semibold text-black text-2xl py-3">
              Request your first payout
            </p>
            <p className="text-sm font-medium text-black mb-4">
              Minimum payout amount is KES 100
            </p>
          </>
        ) : (
          <p className="font-semibold text-sm text-black md:text-2xl py-3">
            Activate your account to start accepting payments & request a payout
          </p>
        )}

        <Button
          onClick={() => handleModalStateChange(true)}
          disabled={Boolean(business && numericBalance === 0)}
          className={cn(
            'p-5 md:h-[60px] h-[48px] rounded-lg w-[253px]',
            business && numericBalance === 0
              ? 'bg-greyish text-white'
              : 'bg-smoothRed text-sm font-medium'
          )}>
          {business ? 'Request payout' : 'Complete account activation'}
        </Button>
      </div>
    </>
  );

  return (
    <div className="min-h-screen">
      <div className="lg:hidden">
        {isPayoutFormOpen ? (
          <div>
            <PayoutFormContent {...formProps} />
          </div>
        ) : (
          <MainContent />
        )}
      </div>

      <div className="hidden lg:flex flex-col">
        <MainContent />

        <Modal open={isModalOpen} onOpenChange={handleModalStateChange}>
          <ModalOverlay className="bg-blue-900/30" />
          <ModalContent className="p-6 md:max-w-[480px]">
            <PayoutFormContent {...formProps} />
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
};

export default Payouts;
