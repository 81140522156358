import React, {FC, isValidElement} from 'react';

import ExportIcon from '../../assets/icons/export.svg';
import {cn} from '../../utils/reusableFunctions';

type TableColumnType = string;
type TableDataType = {
  [key: string]:
    | string
    | {image: JSX.Element; text: string; text2?: string}
    | JSX.Element;
};

interface CommonTableProps {
  columns: TableColumnType[];
  data: TableDataType[];
  onSelect?: (row: TableDataType) => void;
  hideHeaders?: boolean;
}

const CommonTable: FC<CommonTableProps> = ({
  columns,
  data,
  onSelect,
  hideHeaders,
}) => {
  return data.length ? (
    <table className="w-full table-auto text-left text-base text-cashia-grey">
      <thead
        className={cn('mb-3 font-semibold uppercase leading-5', {
          hidden: hideHeaders,
        })}>
        <tr className="border-b border-cashia-grey">
          {columns.map((column, key) => (
            <th className="py-3" key={key}>
              <p className="flex gap-1 font-semibold">{column}</p>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="font-medium">
        {data.map((row, rowKey) => (
          <tr
            key={rowKey}
            className="border-b border-cashia-grey py-5 leading-5">
            {columns.map((col, colKey) => (
              <td key={colKey} className="py-3">
                {typeof row[col] === 'string' ? (
                  <span>{row[col]}</span>
                ) : row[col] && 'image' in row[col] && 'text' in row[col] ? (
                  <div className="flex items-center gap-2">
                    {row[col].image}
                    <div className="flex flex-col">
                      <span>{row[col].text}</span>
                      {row[col].text2 && (
                        <span className="text-sm text-gray-500">
                          {row[col].text2}
                        </span>
                      )}
                    </div>
                  </div>
                ) : isValidElement(row[col]) ? (
                  <>{row[col]}</>
                ) : null}
              </td>
            ))}
            <td className="py-3 flex justify-end h-full pt-5">
              <img
                src={ExportIcon}
                alt="Export"
                className="cursor-pointer"
                onClick={() => onSelect?.(row)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <></>
  );
};

export default CommonTable;
