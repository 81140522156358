import {ReactComponent as DefaultBankIcon} from '../../assets/icons/default_bank.svg';
import {ReactComponent as ABC} from '../../assets/logos/banks/ABC.svg';
import {ReactComponent as Baroda} from '../../assets/logos/banks/Bank_of_Baroda.svg';
import {ReactComponent as BaIndia} from '../../assets/logos/banks/Bank_of_India.svg';
import {ReactComponent as Coop} from '../../assets/logos/banks/CO-OP.svg';
import {ReactComponent as CitiB} from '../../assets/logos/banks/Citi_Bank.svg';
import {ReactComponent as Consolidate} from '../../assets/logos/banks/Consolidated_Bank.svg';
import {ReactComponent as CreditB} from '../../assets/logos/banks/Credit_Bank.svg';
import {ReactComponent as DBK} from '../../assets/logos/banks/DBK.svg';
import {ReactComponent as DTB} from '../../assets/logos/banks/DTB.svg';
import {ReactComponent as EcoBank} from '../../assets/logos/banks/EcoBank.svg';
import {ReactComponent as EquityLogo} from '../../assets/logos/banks/Equity.svg';
import {ReactComponent as Family} from '../../assets/logos/banks/Family_Bank.svg';
import {ReactComponent as Faulu} from '../../assets/logos/banks/Faulu_Bank.svg';
import {ReactComponent as GTB} from '../../assets/logos/banks/GT_Bank.svg';
import {ReactComponent as Guardian} from '../../assets/logos/banks/Guardian_Bank.svg';
import {ReactComponent as Gulf} from '../../assets/logos/banks/Gulf_African_Bank.svg';
import {ReactComponent as Habib} from '../../assets/logos/banks/Habib_Bank.svg';
import {ReactComponent as HousingFinance} from '../../assets/logos/banks/Housing_Finance_Bank.svg';
import {ReactComponent as KCBLogo} from '../../assets/logos/banks/KCB.svg';
import {ReactComponent as KWFT} from '../../assets/logos/banks/KWFT.svg';
import {ReactComponent as NBK} from '../../assets/logos/banks/NBK.svg';
import {ReactComponent as NCBA} from '../../assets/logos/banks/NCBA.svg';
import {ReactComponent as Oriental} from '../../assets/logos/banks/Oriental_Bank.svg';
import {ReactComponent as Paramount} from '../../assets/logos/banks/Paramount_Bank.svg';
import {ReactComponent as PostB} from '../../assets/logos/banks/Post_Bank.svg';
import {ReactComponent as PrimeB} from '../../assets/logos/banks/Prime_Bank.svg';
import {ReactComponent as SBM} from '../../assets/logos/banks/SBM_Bank.svg';
import {ReactComponent as Sidian} from '../../assets/logos/banks/Sidian_Bank.svg';
import {ReactComponent as Spire} from '../../assets/logos/banks/Spire_Bank.svg';
import {ReactComponent as Stanbic} from '../../assets/logos/banks/Stanbic.svg';
import {ReactComponent as UBA} from '../../assets/logos/banks/UBA.svg';
import {ReactComponent as Victoria} from '../../assets/logos/banks/Victoria_Bank.svg';
import {ReactComponent as Stanchart} from '../../assets/logos/banks/standard_chartered.svg';

type BankInfo = {
  logo: React.FC<React.SVGProps<SVGSVGElement>>;
  variations: string[];
};

export const BANK_LOGOS: Record<string, BankInfo> = {
  'ABC Bank': {
    logo: ABC,
    variations: ['african banking corporation', 'abc bank'],
  },
  'Bank of India': {
    logo: BaIndia,
    variations: ['bank of india'],
  },
  'Bank of Baroda': {
    logo: Baroda,
    variations: ['bank of baroda'],
  },
  Citibank: {
    logo: CitiB,
    variations: ['citibank', 'citi bank'],
  },
  'Consolidated Bank': {
    logo: Consolidate,
    variations: ['consolidated bank of kenya', 'consolidated'],
  },
  'Co-operative Bank': {
    logo: Coop,
    variations: ['cooperative bank of kenya', 'co-op', 'cooperative'],
  },
  'Credit Bank': {
    logo: CreditB,
    variations: ['credit bank p.l.c'],
  },
  'Development Bank': {
    logo: DBK,
    variations: ['development bank of kenya', 'dbk'],
  },
  'Diamond Trust Bank': {
    logo: DTB,
    variations: ['diamond trust bank (dtb)', 'dtb'],
  },
  Ecobank: {
    logo: EcoBank,
    variations: ['ecobank kenya', 'eco bank'],
  },
  'Equity Bank': {
    logo: EquityLogo,
    variations: ['equity bank kenya', 'equity bank limited'],
  },
  'Family Bank': {
    logo: Family,
    variations: ['family bank ltd'],
  },
  'Faulu Bank': {
    logo: Faulu,
    variations: ['faulu microfinance bank'],
  },
  'GT Bank': {
    logo: GTB,
    variations: ['guaranty trust bank', 'gtbank'],
  },
  'Guardian Bank': {
    logo: Guardian,
    variations: ['guardian bank ltd'],
  },
  'Gulf African Bank': {
    logo: Gulf,
    variations: ['gulf african bank ltd'],
  },
  'Habib Bank': {
    logo: Habib,
    variations: ['habib bank ag zurich'],
  },
  'Housing Finance': {
    logo: HousingFinance,
    variations: ['housing finance bank'],
  },
  'KCB Bank': {
    logo: KCBLogo,
    variations: ['kenya commercial bank', 'kcb'],
  },
  KWFT: {
    logo: KWFT,
    variations: ['kenya women microfinance bank'],
  },
  'National Bank': {
    logo: NBK,
    variations: ['national bank of kenya', 'nbk'],
  },
  'NCBA Bank': {
    logo: NCBA,
    variations: ['ncba bank kenya'],
  },
  'Oriental Bank': {
    logo: Oriental,
    variations: ['m oriental bank'],
  },
  'Paramount Bank': {
    logo: Paramount,
    variations: ['paramount bank ltd'],
  },
  'Post Bank': {
    logo: PostB,
    variations: ['kenya post office savings bank', 'postbank'],
  },
  'Prime Bank': {
    logo: PrimeB,
    variations: ['prime bank (kenya)'],
  },
  'SBM Bank': {
    logo: SBM,
    variations: ['state bank of mauritius', 'sbm bank kenya'],
  },
  'Sidian Bank': {
    logo: Sidian,
    variations: ['sidian'],
  },
  'Spire Bank': {
    logo: Spire,
    variations: ['spire'],
  },
  'Stanbic Bank': {
    logo: Stanbic,
    variations: ['stanbic holdings'],
  },
  'Standard Chartered': {
    logo: Stanchart,
    variations: ['standard chartered kenya'],
  },
  'UBA Bank': {
    logo: UBA,
    variations: ['united bank for africa', 'uba'],
  },
  'Victoria Bank': {
    logo: Victoria,
    variations: ['victoria commercial bank'],
  },
};

export const getBankLogo = (bankName: string | undefined) => {
  if (!bankName) return DefaultBankIcon;

  const normalized = bankName.toLowerCase().trim();

  const exactMatch = Object.entries(BANK_LOGOS).find(
    ([key]) => key.toLowerCase() === normalized
  );
  if (exactMatch) return exactMatch[1].logo;

  const matchByVariation = Object.entries(BANK_LOGOS).find(([_, info]) =>
    info.variations.some((variant) => normalized.includes(variant))
  );
  if (matchByVariation) return matchByVariation[1].logo;
  return DefaultBankIcon;
};
