import {toast} from '@cashiaApp/web-components';

import {
  CurrencyCode,
  useRequestPayoutMutation,
  useSendPayoutCodeMutation,
} from '../../../graphql/generated';

export const usePayoutOperations = () => {
  const [requestPayout, {loading: requestLoading}] = useRequestPayoutMutation({
    onCompleted: (data) => {
      if (data?.requestPayout?.id) {
        toast.success('Verification code sent to your phone');
      }
    },
    onError: (error) => {
      console.error('Payout request error:', error);
      toast.error(error.message);
    },
  });

  const [sendCode, {loading: sendingCode}] = useSendPayoutCodeMutation({
    onCompleted: () => {
      toast.success('Verification code sent to your phone');
    },
    onError: (error) => {
      console.error('Send code error:', error);
      toast.error(error.message || 'Failed to send verification code');
    },
  });

  const handlePayoutRequest = async (amount: number) => {
    try {
      const response = await requestPayout({
        variables: {
          input: {
            amount: {
              amountInCents: Math.round(amount * 100),
              currencyCode: CurrencyCode.Kes,
            },
          },
        },
      });

      const payoutId = response.data?.requestPayout.id;
      if (payoutId) {
        return payoutId;
      }
      return null;
    } catch (error) {
      console.error('Payout operation failed:', error);
      return null;
    }
  };

  const handleResendCode = async (payoutId: string) => {
    try {
      await sendCode({
        variables: {payoutId},
      });
      return true;
    } catch (error) {
      console.error('Code resend failed:', error);
      return false;
    }
  };

  return {
    requestPayout: handlePayoutRequest,
    resendCode: handleResendCode,
    isLoading: requestLoading || sendingCode,
  };
};
