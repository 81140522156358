import {ApolloError} from '@apollo/client';
import {Button, toast, useMediaQuery} from '@cashiaApp/web-components';
import Payment from 'payment';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import CardPay from './components/card';
import Inactive from './components/inactive';
import MPesaPay from './components/mpesa';
import processingGif from '../../assets/animations/processing.gif';
import {ReactComponent as ShopIcon} from '../../assets/icons/sidebar/shop.svg';
import {ReactComponent as CashiaLogo} from '../../assets/logos/cashia_full_logo_red.svg';
import logo from '../../assets/logos/cashia_logo_pink.svg';
import {ReactComponent as MastercardLogo} from '../../assets/logos/mastercard.svg';
import {ReactComponent as MpesaLogo} from '../../assets/logos/mpesa.svg';
import {ReactComponent as MpesaSmallLogo} from '../../assets/logos/mpesa_small.svg';
import {ReactComponent as PciLogo} from '../../assets/logos/pci.svg';
import {ReactComponent as VisaLogo} from '../../assets/logos/visa.svg';
import LoadingModal from '../../components/common/LoadingModal';
import Modal from '../../components/tailwind/Modal';
import Spinner from '../../components/tailwind/Spinner';
import {
  CardIssuer,
  OrderPaymentMode,
  useAddOrderMutation,
  useGetPaymentLinkQuery,
  useStepUpOrderMutation,
} from '../../graphql/generated';
import {cn} from '../../utils/reusableFunctions';
import validateKenyanPhoneNumber from '../../utils/validatePhoneNumber';
import Tabs from '../businessProfile/components/Tabs';

type Props = {
  method?: 'card' | 'mpesa';
};

export type CardInfo = {
  firstName?: string;
  lastName?: string;
  email?: string;
  number?: string;
  expiration?: string;
  cvvcode?: string;
};

type MessageData = {
  MessageType: string;
  SessionId: string;
  status: boolean;
};

const Footer = () => {
  return (
    <div className="font-[500] text-foggy flex justify-between px-10 max-md:px-0 max-md:mt-5 max-md:pt-[20%] max-md:pb-5">
      <p className="max-md:text-[13px]">
        <a href="https://www.cashia.com/">
          Powered by <span className="text-smoothRed font-bold">Cashia</span>
        </a>
      </p>
      <div className="flex gap-4 max-md:gap-2">
        <p className="border-r-[1px] border-greyish pr-6 max-md:pr-2 max-md:text-[12px]">
          Terms & conditions
        </p>
        <p className="max-md:text-[12px]">Privacy policy</p>
      </div>
    </div>
  );
};

export default function PaymentView({method = 'mpesa'}: Props) {
  const {origin} = new URL(window.location.href);
  const {businessName, reference} = useParams();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [cardData, setCardData] = useState<CardInfo>({
    firstName: '',
    lastName: '',
    email: '',
    number: '',
    expiration: '',
    cvvcode: '',
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [cardModalOpen, setCardModalOpen] = useState(false);
  const [cardOrderLoading, setCardOrderLoading] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const [paid, setPaid] = useState(false);
  const [isValidCardData, setIsValidCardData] = useState(false);
  const navigate = useNavigate();
  const [addOrder, {loading: addOrderLoading, data: paymentResData}] =
    useAddOrderMutation();
  const [stepUpOrder, {loading: stepUpOrderLoading}] = useStepUpOrderMutation();
  const stepUpRef = useRef(null);

  const {data, loading} = useGetPaymentLinkQuery({
    variables: {
      reference,
    },
    skip: !reference,
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    setPaid(false);
  }, [method]);

  const baseLink = `/link/${
    businessName?.toLocaleLowerCase().trim().replace(/\s+/g, '-') || ''
  }/${reference || ''}`;

  const tabs = useMemo(() => {
    const newURL = new URL(`${origin}`);
    newURL.pathname = baseLink;
    return [
      {
        name: 'M-Pesa',
        link: `${newURL.pathname}`,
      },
      {
        name: 'Card',
        link: `${newURL.pathname}/card`,
      },
    ];
  }, [origin, baseLink]);

  const btnDisabled = useMemo(() => {
    if (method === 'mpesa') {
      return !validateKenyanPhoneNumber(phoneNumber) || !phoneNumber;
    } else {
      return !isValidCardData;
    }
  }, [phoneNumber, method, isValidCardData]);

  const handlePhoneNumberChange = (val: string) => {
    setPhoneNumber(val);
    if (!validateKenyanPhoneNumber(val) || !/^[0-9]+$/.test(val)) {
      setErrorMessage('Please enter a valid kenyan number');
    } else {
      setErrorMessage('');
    }
  };

  //mock success flow
  useEffect(() => {
    if (!modalOpen) return;
    const showModal = setTimeout(() => {
      setModalOpen(false);
      setPaid(true);
    }, 3000);
    return () => {
      setModalOpen(false);
      clearTimeout(showModal);
    };
  }, [modalOpen]);

  useEffect(() => {
    if (paymentResData?.addOrder.card?.flowData?.actionUrl) {
      const collectionForm = document.getElementById(
        'cardinal_collection_form'
      ) as HTMLFormElement;

      if (collectionForm) {
        collectionForm.submit();
      } else {
        console.error('Form not found inside the iframe.');
      }

      const loadHandler = (event: MessageEvent) => {
        const eventOrigin = event.origin;
        let messageData: MessageData;

        if (typeof event.data === 'string') {
          try {
            messageData = JSON.parse(event.data) as MessageData;
          } catch {
            messageData = {} as MessageData;
          }
        } else {
          messageData = event.data as MessageData;
        }

        if (
          eventOrigin === process.env.REACT_APP_API_CENTINEL_URL &&
          messageData?.MessageType === 'profile.completed'
        ) {
          setTimeout(() => {
            stepUpOrder({
              variables: {
                input: {
                  paymentLinkId: data?.paymentLink?.id || '',
                  paymentMode:
                    method === 'card'
                      ? OrderPaymentMode.Card
                      : OrderPaymentMode.Mpesa,
                  phoneNumber:
                    method !== 'card'
                      ? {
                          countryCode: '+254',
                          number: phoneNumber || '',
                        }
                      : undefined,
                  firstName:
                    method === 'card' ? cardData?.firstName : undefined,
                  lastName: method === 'card' ? cardData?.lastName : undefined,
                  email: method === 'card' ? cardData.email : undefined,
                  card:
                    method === 'card'
                      ? {
                          issuer:
                            Payment.fns.cardType(cardData.number || '') ===
                            'visa'
                              ? CardIssuer.Visa
                              : CardIssuer.Mastercard,
                          number: cardData.number,
                          expiryMonth: `${parseInt(
                            cardData.expiration?.slice(0, 2) || '',
                            10
                          )}`,
                          expiryYear: `${
                            parseInt(
                              cardData.expiration?.slice(3, 5) || '',
                              10
                            ) + 2000
                          }`,
                          cvv: cardData.cvvcode,
                          flowData: {
                            ...paymentResData.addOrder.card?.flowData,
                            actionUrl: `${origin}${baseLink}/card/status`,
                          },
                        }
                      : undefined,
                },
              },
            })
              .then((res) => {
                if (res.data?.stepUpOrder) {
                  const stepUpFlowData = res.data.stepUpOrder.card?.flowData;
                  const stepUpForm = document.getElementById(
                    'step-up-form'
                  ) as HTMLFormElement;
                  if (stepUpForm) {
                    const jwtInput = document.getElementById(
                      'step-up-jwt'
                    ) as HTMLInputElement;
                    const mdInput = document.getElementById(
                      'step-up-md'
                    ) as HTMLInputElement;

                    jwtInput.value = stepUpFlowData?.token || '';
                    mdInput.value = stepUpFlowData?.referenceId || '';
                    stepUpForm.action = stepUpFlowData?.actionUrl || '';

                    stepUpForm.submit();
                    setCardOrderLoading(false);
                  } else {
                    console.error('Form not found inside the iframe.');
                  }
                }
              })
              .catch((err: ApolloError) => {
                toast.error(err.message);
              });
          }, 2000);
        }
      };
      window?.addEventListener('message', loadHandler);
      return () => window?.removeEventListener('message', loadHandler);
    }
  }, [
    paymentResData,
    cardData,
    method,
    phoneNumber,
    stepUpOrder,
    data,
    baseLink,
    origin,
  ]);

  const navToPaymentStatus = (status: string) => {
    navigate(`${baseLink}/${method === 'card' ? 'card/' : ''}status`, {
      state: {
        status,
        phoneNumber,
        paymentResData,
        cardData: method === 'card' ? cardData : undefined,
      },
    });
  };

  const handleSubmit = () => {
    if (!data?.paymentLink?.id) return;
    if (method === 'mpesa') {
      setModalOpen(true);
    }
    addOrder({
      variables: {
        input: {
          paymentLinkId: data.paymentLink.id,
          paymentMode:
            method === 'card' ? OrderPaymentMode.Card : OrderPaymentMode.Mpesa,
          phoneNumber:
            method !== 'card'
              ? {
                  countryCode: '+254',
                  number: phoneNumber || '',
                }
              : undefined,
          firstName: method === 'card' ? cardData?.firstName : undefined,
          lastName: method === 'card' ? cardData?.lastName : undefined,
          email: method === 'card' ? cardData.email : undefined,
          card:
            method === 'card'
              ? {
                  issuer:
                    Payment.fns.cardType(cardData.number || '') === 'visa'
                      ? CardIssuer.Visa
                      : CardIssuer.Mastercard,
                  number: cardData.number,
                  expiryMonth: `${parseInt(
                    cardData.expiration?.slice(0, 2) || '',
                    10
                  )}`,
                  expiryYear: `${
                    parseInt(cardData.expiration?.slice(3, 5) || '', 10) + 2000
                  }`,
                  cvv: cardData.cvvcode,
                }
              : undefined,
        },
      },
    })
      .then((res) => {
        if (res.data?.addOrder) {
          setPaid(true);
          if (method === 'card') {
            setCardModalOpen(true);
            setCardOrderLoading(true);
          }
        }
      })
      .catch((err: ApolloError) => {
        toast.error(err.message);
      });
  };

  if (loading) {
    return <LoadingModal open />;
  }

  return (
    <>
      <div className="h-screen">
        {data?.paymentLink.active ? (
          <>
            <div className="h-full">
              <div>
                <div className="flex justify-between border-b-[1px] border-greyish px-4 items-center lg:h-[65px]">
                  <div className="flex gap-2 my-3 items-center">
                    <ShopIcon width={24} />
                    <p className="italic font-[600] max-md:text-[13px]">
                      {data?.paymentLink?.business?.name || '---'}
                    </p>
                  </div>
                  <img src={logo} width={24} />
                </div>
              </div>
              <div className="px-14 max-md:px-4 flex h-full max-md:flex-col">
                <div className="max-md:w-full w-[50%] max-md:p-0 p-10 max-md:border-0 border-r-[1px] border-greyish">
                  <p className="text-[34px] max-md:text-[20px] my-3 font-[600]">
                    Pay KES{' '}
                    {new Intl.NumberFormat('en-US').format(
                      Number(
                        (data?.paymentLink?.cost?.amountInCents || 0) / 1000
                      )
                    ) || '...'}
                    .00
                  </p>
                  <div className="border-greyish border-[1px] rounded-[5px] p-6 max-md:p-3 gap-8 flex flex-col">
                    <div className="flex justify-between overflow-hidden max-md:gap-5 gap-2">
                      <p className="text-foggy font-[500]">Item:</p>
                      <p className="font-[500] max-w-[500px] max-md:text-[14px]">
                        {data?.paymentLink?.title || '---'}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="text-foggy font-[500]">Pay to:</p>
                      <p className="font-[500] text-end max-md:text-[14px]">
                        {data?.paymentLink?.business?.name || '---'}
                      </p>
                    </div>
                  </div>
                  {isDesktop && (
                    <div className="flex flex-col items-center max-md:items-start">
                      <p className="my-14 text-foggy max-md:text-[13px] max-md:w-[255px]">
                        Cashia is licenced and regulated by the Central Bank of
                        Kenya
                      </p>
                      <div className="w-full flex flex-col gap-10 max-md:gap-2 items-center max-md:items-start max-md:w-[255px]">
                        <div className="flex gap-10 max-md:gap-4 items-center">
                          <CashiaLogo className="max-md:w-[55px]" />
                          <MpesaLogo className="max-md:w-[60px]" />
                          <VisaLogo className="max-md:w-[50px]" />
                          <MastercardLogo className="max-md:w-[50px]" />
                        </div>
                        <PciLogo className="max-md:w-[50px]" />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className="max-md:w-full w-[50%] pt-14 px-16
 max-md:p-0">
                  <p className="my-4 font-[600] text-[20px] max-md:text-[15px]">
                    Choose payment method below
                  </p>
                  <Tabs tabs={tabs} />
                  {method === 'card' ? (
                    <CardPay
                      cardData={cardData}
                      setCardData={setCardData}
                      setIsValidCardData={setIsValidCardData}
                    />
                  ) : (
                    <MPesaPay
                      phoneNumber={phoneNumber}
                      setPhoneNumber={handlePhoneNumberChange}
                      errorMessage={errorMessage}
                    />
                  )}
                  <div className="text-center">
                    <Button
                      onClick={handleSubmit}
                      className={cn(
                        'h-[52px] w-full bg-pink mt-3 active:scale-95',
                        {
                          'text-white bg-greyish cursor-default': btnDisabled,
                        }
                      )}
                      disabled={btnDisabled}>
                      {addOrderLoading ? (
                        <Spinner className="fill-white" />
                      ) : (
                        <p className="font-[600]">Pay</p>
                      )}
                    </Button>
                  </div>
                  {/* mocked status action buttons */}
                  {paid && (
                    <div className="flex justify-between mt-6">
                      <Button
                        onClick={() => navToPaymentStatus('successful')}
                        className="bg-green-400">
                        Successful
                      </Button>
                      <Button
                        onClick={() => navToPaymentStatus('pending')}
                        className="bg-yellow-400">
                        Pending
                      </Button>
                      <Button
                        onClick={() => navToPaymentStatus('failed')}
                        className="bg-red-400">
                        Failed
                      </Button>
                    </div>
                  )}
                </div>
                {!isDesktop && (
                  <div className="flex flex-col items-center max-md:items-start">
                    <p className="mt-8 text-foggy max-md:text-[13px] max-md:w-[230px]">
                      Cashia is licenced and regulated by the Central Bank of
                      Kenya
                    </p>
                    <div className="w-full flex flex-col gap-10 max-md:gap-1 items-center max-md:items-start max-md:w-[255px]">
                      <div className="flex gap-10 max-md:gap-4 items-center">
                        <CashiaLogo className="max-md:w-[65px]" />
                        <MpesaLogo className="max-md:w-[60px]" />
                        <VisaLogo className="max-md:w-[50px]" />
                        <MastercardLogo className="max-md:w-[50px]" />
                      </div>
                      <PciLogo className="max-md:w-[50px]" />
                    </div>
                  </div>
                )}
                {!isDesktop && <Footer />}
              </div>
            </div>
            {isDesktop && <Footer />}
            <Modal
              isVisible={modalOpen}
              onClose={() => setModalOpen(false)}
              className="w-[90%] max-w-lg md:max-w-xl lg:max-w-2xl h-auto">
              <div>
                <div
                  className="h-[200px] px-10 max-md:px-4 rounded-tr-[10px] rounded-tl-[10px]"
                  style={{
                    backgroundImage: `url(${processingGif})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}>
                  <div>
                    <div className="flex gap-3 pt-[15%] mb-4 items-center">
                      <MpesaSmallLogo />
                      <p className="text-white font-[500]">
                        Pay {data?.paymentLink?.business?.name} via MPESA
                      </p>
                    </div>
                    <p className="w-[310px] text-[13px] text-white font-[500]">
                      An STK push has been sent to +254 {phoneNumber}. Enter
                      your PIN to complete the payment.
                    </p>
                  </div>
                </div>
                <div className="px-10 max-md:px-4 py-5">
                  <div className="flex w-full justify-between border-b-[1px] border-greyish py-6">
                    <p className="font-[500] text-foggy">Amount:</p>
                    <p className="font-[600]">
                      {new Intl.NumberFormat('en-US').format(
                        Number(
                          (data?.paymentLink?.cost?.amountInCents || 0) / 1000
                        )
                      ) || '...'}
                      .00
                    </p>
                  </div>
                  <div className="flex w-full justify-between border-b-[1px] border-greyish py-6">
                    <p className="font-[500] text-foggy">Method:</p>
                    <p className="font-[600]">MPESA</p>
                  </div>
                  <div className="flex w-full justify-between py-6">
                    <p className="font-[500] text-foggy">Mobile no:</p>
                    <p className="font-[600]">+254 {phoneNumber}</p>
                  </div>
                </div>
              </div>
            </Modal>
            {paymentResData?.addOrder.card?.flowData ? (
              <Modal
                isVisible={cardModalOpen}
                onClose={() => setCardModalOpen(false)}
                className={cn('md:w-[400px] md:h-[550px]', {
                  'md:h-[120px]': cardOrderLoading,
                })}>
                {(cardOrderLoading || stepUpOrderLoading) && (
                  <div className="flex items-center flex-col">
                    <Spinner className="mt-8 size-10 self-center w-full" />
                    <p>Processing card details...</p>
                  </div>
                )}
                <div>
                  <iframe
                    id="cardinal_collection_iframe"
                    name="collectionIframe"
                    height="10"
                    width="10"
                    style={{display: 'none'}}
                  />
                  <form
                    id="cardinal_collection_form"
                    method="POST"
                    target="collectionIframe"
                    action={
                      paymentResData?.addOrder.card?.flowData?.actionUrl || ''
                    }>
                    <input
                      id="cardinal_collection_form_input"
                      type="hidden"
                      name="JWT"
                      value={
                        paymentResData?.addOrder.card?.flowData?.token || ''
                      }
                    />
                  </form>

                  <iframe
                    id="step-up-iframe"
                    name="step-up-iframe"
                    height={cardOrderLoading ? '0' : '530'}
                    width="100%"
                    style={{border: 'none'}}
                    ref={stepUpRef}
                  />
                  <form id="step-up-form" target="step-up-iframe" method="POST">
                    <input type="hidden" id="step-up-jwt" name="JWT" />
                    <input type="hidden" id="step-up-md" name="MD" />
                  </form>
                </div>
              </Modal>
            ) : (
              <div />
            )}
          </>
        ) : (
          <Inactive />
        )}
      </div>
    </>
  );
}
