import {Button} from '@cashiaApp/web-components';
import React from 'react';

import {ReactComponent as ShopIcon} from '../../../assets/icons/sidebar/shop.svg';
import partners from '../../../assets/images/partnersDesktop.png';
import phoneInputDesktop from '../../../assets/images/phoneInputDesktop.png';
import logo from '../../../assets/logos/cashia_logo_pink.svg';

type Props = {
  title: string;
  amount: string;
  name?: string;
};

export default function DesktopPreview({title, amount, name}: Props) {
  return (
    <div className="w-[90%] h-[600px] rounded-[20px] border-4 border-black bg-white">
      <div>
        <div className="flex justify-between border-b-[1px] border-greyish px-4 items-center">
          <div className="flex gap-2 my-3 items-center">
            <ShopIcon width={24} />
            <p className="text-[11px] italic font-[600] text-[13px]">
              {name || '---'}
            </p>
          </div>
          <img src={logo} width={24} />
        </div>
      </div>
      <div className="px-3 flex">
        <div className="w-[50%] p-10 border-r-[1px] border-greyish h-[530px]">
          <p className="text-md my-3 font-[600]">Pay KES {amount || '.....'}</p>
          <div className="border-greyish border-[1px] rounded-[5px] p-3">
            <div className="flex justify-between mb-5 overflow-hidden">
              <p className="text-[11px] text-foggy">Item:</p>
              <p className="text-[11px] font-[500] max-w-[500px]">
                {title || '---'}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="text-[11px] text-foggy">Pay to:</p>
              <p className="text-[11px] font-[500]">{name || '---'}</p>
            </div>
          </div>
        </div>
        <div className="w-[50%] p-10">
          <p className="text-[11px] my-4 font-[600]">
            Choose payment method below
          </p>
          <div className="text-center">
            <img src={phoneInputDesktop} />
            <Button className="h-[35px] w-full bg-greyish mt-3" disabled>
              <p className="text-[12px]">Pay</p>
            </Button>
            <p className="text-[11px] my-6 text-foggy">
              Cashia is licenced and regulated by the Central Bank of Kenya
            </p>
            <img src={partners} />
          </div>
        </div>
      </div>
      <div className="relative bottom-6 left-5 text-[10px] font-[500] text-foggy flex justify-between pr-10">
        <p>
          <a href="https://www.cashia.com/">
            Powered by <span className="text-smoothRed font-bold">Cashia</span>
          </a>
        </p>
        <div className="flex gap-4">
          <p className="border-r-[1px] border-greyish w-[120px]">
            Terms & conditions
          </p>
          <p>Privacy policy</p>
        </div>
      </div>
    </div>
  );
}
