import {Button} from '@cashiaApp/web-components';
import {useNavigate} from 'react-router-dom';

import {ReactComponent as NotFoundIcon} from '../../assets/404.svg';
import {ReactComponent as LogoIcon} from '../../assets/logos/cashia_logo_pink.svg';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-between h-screen w-screen p-8 overflow-hidden">
      <LogoIcon className="shrink-0" />
      <div className="flex flex-col gap-14 pb-10 w-full justify-center items-center">
        <NotFoundIcon className="" />
        <div className="flex flex-col w-full items-center">
          <h2 className="font-semibold text-[50px] text-center">Oops...</h2>
          <h3 className="text-[20px] font-medium">
            You seem to have fallen down the rabbit hole.
          </h3>
          <Button onClick={() => navigate('/home')} className="w-fit mt-3">
            Go home
          </Button>
        </div>
      </div>
      <p className="text-[16px] text-[#757575] font-medium shrink-0">
        <a href="https://www.cashia.com/">
          Powered by <span className="text-smoothRed font-bold">Cashia</span>
        </a>
      </p>
    </div>
  );
};

export default NotFound;
