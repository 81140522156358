import {
  Button,
  toast,
  useMediaQuery,
  Modal,
  ModalClose,
  ModalContent,
  ModalDescription,
  ModalHeader,
  ModalTitle,
  ModalTrigger,
} from '@cashiaApp/web-components';
import {format} from 'date-fns';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {useLocation, useNavigate, useOutletContext} from 'react-router-dom';

import {ReactComponent as ActivateIcon} from '../../../assets/icons/activate.svg';
import {ReactComponent as ArrowIcon} from '../../../assets/icons/arrow-right.svg';
import {ReactComponent as CopyIcon} from '../../../assets/icons/copy.svg';
import {ReactComponent as DeactivateIcon} from '../../../assets/icons/deactivate.svg';
import {ReactComponent as EditIcon} from '../../../assets/icons/edit.svg';
import {ReactComponent as EmptyIcon} from '../../../assets/icons/empty-sad-face.svg';
import {ReactComponent as LinkIcon} from '../../../assets/icons/link-arrow.svg';
import {ReactComponent as RightArrowIcon} from '../../../assets/icons/right-arrow.svg';
import {ReactComponent as ProfileIcon} from '../../../assets/icons/user-square.svg';
import CustomSpinner from '../../../components/common/CustomSpinner';
import Paginator from '../../../components/common/Paginator';
import Spinner from '../../../components/tailwind/Spinner';
import {
  useGetBusinessQuery,
  useGetPaymentLinkQuery,
  useUpdatePaymentLinkMutation,
  GetPaymentLinkDocument,
  GetPaymentLinkQueryResult,
  useGetNewOrdersSubscription,
} from '../../../graphql/generated';
import {OutletContextProps} from '../../../layouts/MainLayout';
import {cn} from '../../../utils/reusableFunctions';

interface LocationState {
  link: string;
}

export default function LinkDetails() {
  const {origin} = new URL(window.location.href);
  const location = useLocation();
  const linkId = (location.state as LocationState)?.link;
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const [count, setCount] = useState<number>(1);
  const [currentCursor, setCurrentCursor] = useState<string | null>(null);
  const [cursorStack, setCursorStack] = useState<string[]>([]);

  const {data: linkPayments, loading: linkPaymentsLoading} =
    useGetNewOrdersSubscription({
      variables: {
        input: {
          cursor: {
            first: 10,
            after: currentCursor,
          },
          filter: {paymentLinkId: linkId},
        },
      },
    });

  const {data} = useGetPaymentLinkQuery({
    variables: {
      id: linkId,
    },
    skip: !linkId,
  });
  const loadMore = useCallback(
    async (next: boolean) => {
      try {
        if (next && linkPayments?.orders?.pageInfo?.hasNextPage) {
          const nextCursor = linkPayments.orders.pageInfo.endCursor;
          if (nextCursor) {
            if (currentCursor) {
              setCursorStack((prev) => [...prev, currentCursor]);
            }
            setCurrentCursor(nextCursor);
            setCount((prev) => prev + 1);
          }
        } else if (!next && count > 1) {
          if (cursorStack.length > 0) {
            const previousCursor = cursorStack[cursorStack.length - 1];
            if (previousCursor) {
              setCursorStack((prev) => prev.slice(0, -1));
              setCurrentCursor(previousCursor);
              setCount((prev) => prev - 1);
            }
          } else {
            setCurrentCursor(null);
            setCount(1);
          }
        }
      } catch (error) {
        console.error('Error loading more payments:', error);
      }
    },
    [linkPayments, currentCursor, cursorStack, count]
  );

  useEffect(() => {
    setCount(1);
    setCurrentCursor(null);
    setCursorStack([]);
  }, [linkId]);

  const [isActive, setIsActive] = useState<boolean | null>(null);
  const {data: businessData} = useGetBusinessQuery();

  const readableLink = useMemo(() => {
    const newURL = new URL(`${origin}`);
    newURL.pathname = `/link/${
      businessData?.business?.name
        .toLocaleLowerCase()
        .trim()
        .replace(/\s+/g, '-') || ''
    }/${data?.paymentLink?.reference || ''}`;
    return newURL.toString();
  }, [businessData, data, origin]);

  const {setHeaderComponent} = useOutletContext<OutletContextProps>();
  useLayoutEffect(() => {
    if (!isDesktop) return;
    setHeaderComponent(
      linkPaymentsLoading
        ? undefined
        : () => (
            <div className="h-[75px] max-md:h-[35px] flex items-center gap-5 max-md:hidden">
              <p
                className="cursor-pointer font-medium"
                onClick={() => navigate('/payment-links')}>
                Payment links
              </p>
              <RightArrowIcon className="text-foggy font-medium" />
              <p className="text-foggy font-[500]">
                {data?.paymentLink?.title || ''}
              </p>
            </div>
          )
    );
    return () => {
      setHeaderComponent(undefined);
    };
  }, [setHeaderComponent, data, navigate, isDesktop, linkPaymentsLoading]);

  const [updatePaymentLink, {loading}] = useUpdatePaymentLinkMutation();

  useEffect(() => {
    if (data?.paymentLink?.active !== undefined) {
      setIsActive(Boolean(data.paymentLink.active));
    } else {
      setIsActive(null);
    }
  }, [data?.paymentLink?.active]);

  const toggleActiveStatus = async () => {
    try {
      await updatePaymentLink({
        variables: {
          input: {
            id: linkId,
            active: !isActive,
          },
        },
        update: (cache, {data: updateData}) => {
          if (!updateData) {
            return;
          }

          const {updatePaymentLink: paymentLinkDataUpdate} = updateData;

          const existingData = cache.readQuery<GetPaymentLinkQueryResult>({
            query: GetPaymentLinkDocument,
            variables: {id: linkId},
          });

          if (existingData) {
            const updatedLink = {
              ...existingData.data?.paymentLink,
              active: paymentLinkDataUpdate.active,
              createdAt: existingData.data?.paymentLink?.createdAt as string,
              __typename: 'PaymentLink',
            };

            cache.writeQuery({
              query: GetPaymentLinkDocument,
              data: {paymentLink: updatedLink},
              variables: {id: linkId},
            });
          }
        },
        onCompleted: () => {
          setIsActive((prevIsActive) => !prevIsActive);
        },
        onError: (error) => {
          console.error('Error updating payment link', error);
          toast.error('Failed to activate/deactivate payment link.');
        },
      });
    } catch (error) {
      console.error('Error updating payment link', error);
    }
  };

  if (linkPaymentsLoading) {
    return <CustomSpinner />;
  }
  if (isActive === null) {
    return <Spinner />;
  }

  return (
    <div className="flex w-full h-[95%] max-md:h-full overflow-y-scroll max-md:flex-col max-md:pb-[200px]">
      <div className="w-[50%] max-md:w-full lg:border-r-[1px] border-greyish max-md:px-1 pr-10 pt-8 max-md:pt-4 max-md:mb-5">
        <p className="font-[600] text-[24px] mb-1 break-words">
          {data?.paymentLink?.title || '---'}
        </p>
        <p className="font-[500] text-cashiaBlue mb-3">
          {data?.paymentLink?.cost?.currencyCode || 'KES'}{' '}
          {new Intl.NumberFormat('en-US').format(
            Number((data?.paymentLink?.cost?.amountInCents || 0) / 1000)
          )}
        </p>
        <p className="mb-5 font-[500] break-words">
          {data?.paymentLink?.description || ''}
        </p>

        <div
          className={cn(
            'mb-5 w-full min-h-[48px] rounded-[10px] border-greyish border-[1px] flex items-center',
            !isActive && 'opacity-40'
          )}>
          <Button
            onClick={async () => {
              await navigator.clipboard.writeText(readableLink);
              toast.success('Link copied!');
            }}
            disabled={!isActive}
            className="h-[48px] cursor-pointer w-[10%] md:w-[7.5%] bg-offWhite flex items-center justify-center rounded-l-[10px] border-greyish border-r-[1px]">
            <div>
              <CopyIcon className="max-md:h-[20px]" />
            </div>
          </Button>

          <div className="flex-1 px-2 text-[14px] md:text-[16px] overflow-hidden truncate">
            <p>{readableLink}</p>
          </div>

          <Button
            onClick={() => window.open(readableLink, '_blank')}
            className="h-[48px] cursor-pointer w-[10%] md:w-[7.5%] bg-offWhite flex items-center justify-center rounded-r-[10px] border-greyish border-l-[1px]">
            <div>
              <LinkIcon className="max-md:h-[20px]" />
            </div>
          </Button>
        </div>

        <div className="mb-10 w-full h-[148px] rounded-[10px] border-[#DAD8D8] border-[1px] p-4 flex flex-col justify-between">
          <div className="flex justify-between w-full">
            <p className="text-foggy font-[500]">Status:</p>
            {loading ? (
              <p />
            ) : isActive ? (
              <p className="text-green-400">Active</p>
            ) : (
              <p className="text-red-400">Deactivated</p>
            )}
          </div>
          <div className="flex justify-between w-full">
            <p className="text-foggy font-[500]">Created on:</p>
            <p>
              {format(
                new Date((data?.paymentLink?.createdAt as string) || 0),
                'PPP'
              )}
            </p>
          </div>
          <div className="flex justify-between w-full">
            <p className="text-foggy font-[500]">Link limit count:</p>
            <p>{data?.paymentLink?.limit ?? '∞'}</p>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="w-[300px] justify-between flex items-center">
            {!linkPaymentsLoading &&
              (linkPayments?.orders?.edges?.length ?? 0) === 0 && (
                <Button
                  className="w-[105px] h-[48px] border-greyish border-[1px] rounded-[10px] bg-white text-black"
                  onClick={() =>
                    navigate('/payment-links/edit-link', {
                      state: {link: linkId},
                    })
                  }>
                  <EditIcon className="mr-2" />
                  Edit
                </Button>
              )}
            <Modal>
              <ModalTrigger asChild>
                {loading ? (
                  <div className="w-[160px] h-[48px] flex items-center justify-center">
                    <Spinner />
                  </div>
                ) : (
                  <Button
                    className={cn(
                      'w-[160px] h-[48px] rounded-[10px] text-white flex items-center justify-center',
                      isActive ? 'bg-darkRed' : 'bg-darkGreen',
                      (linkPayments?.orders?.edges?.length || 0) > 0
                        ? 'ml-8 mr-8'
                        : ''
                    )}>
                    {isActive ? (
                      <>
                        <DeactivateIcon className="mr-2" />
                        Deactivate
                      </>
                    ) : (
                      <>
                        <ActivateIcon className="mr-2" />
                        Activate
                      </>
                    )}
                  </Button>
                )}
              </ModalTrigger>
              <ModalContent className="lg:w-[25%] md:w-[40%] w-[80%]">
                <div>
                  <ModalHeader>
                    <ModalTitle>
                      {isActive
                        ? 'Deactivate this payment link'
                        : 'Activate this payment link'}
                    </ModalTitle>
                    <ModalDescription className="text-black">
                      {isActive
                        ? 'Customers will no longer be able to make a purchase using this link.'
                        : 'Customers will now be able to make a purchase using this link.'}
                    </ModalDescription>
                    <ModalDescription className="text-black">
                      {`You can choose to ${
                        isActive ? 'reactivate' : 'deactivate'
                      } this payment link at any time.`}
                    </ModalDescription>
                  </ModalHeader>
                </div>
                <div className="flex gap-x-3">
                  <ModalClose asChild>
                    <button className="border rounded-lg bg-white p-2.5">
                      Cancel
                    </button>
                  </ModalClose>
                  <ModalClose asChild>
                    <button
                      className={cn(
                        'border rounded-lg p-3 text-white',
                        isActive ? 'bg-darkRed' : 'bg-darkGreen'
                      )}
                      onClick={toggleActiveStatus}>
                      {isActive ? 'Deactivate' : 'Activate'}
                    </button>
                  </ModalClose>
                </div>
              </ModalContent>
            </Modal>
          </div>
        </div>
      </div>
      <div className="w-[50%] px-10 pt-8 max-md:border-t-[1px] border-greyish max-md:px-1 max-md:w-full flex flex-col h-full">
        <p className="font-[600] text-foggy">PAYMENTS</p>

        {linkPaymentsLoading ? (
          <div className="flex justify-center items-center h-full">
            <Spinner />
          </div>
        ) : linkPayments?.orders?.edges?.length ? (
          <div className="flex flex-col flex-grow ">
            {linkPayments?.orders?.edges?.map((item) => (
              <div
                key={item?.node?.id}
                className="flex items-center justify-between border-b-[1px] border-greyish py-[10px]"
                onClick={() =>
                  isDesktop &&
                  navigate(`/all-payments/payments/details/${item?.node?.id}`)
                }>
                <div className="flex items-center gap-2 lg:gap-5 justify-between">
                  <ProfileIcon />
                  <div className="flex-grow">
                    <p className="text-[15px] font-[500]">
                      {item?.node?.firstName || 'Melissa'}{' '}
                      {item?.node?.lastName || 'Mukami'}
                    </p>
                    <p className="text-foggy text-[13px]">
                      {item?.node?.phoneNumber?.countryCode}{' '}
                      {item?.node?.phoneNumber?.number}
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-2 lg:gap-14">
                  <div className="flex">
                    <p className="text-green-400 font-[500] text-end">
                      + {item?.node?.cost?.currencyCode}{' '}
                      {new Intl.NumberFormat('en-US').format(
                        Number(item?.node?.cost?.amountInCents / 1000)
                      )}
                    </p>
                  </div>
                  <div
                    className="flex items-end"
                    onClick={() =>
                      isDesktop &&
                      navigate(
                        `/all-payments/payments/details/${item?.node?.id}`
                      )
                    }>
                    {isDesktop ? (
                      <LinkIcon className="max-md:h-[20px]" />
                    ) : (
                      <ArrowIcon />
                    )}
                  </div>
                </div>
              </div>
            ))}
            {(linkPayments?.orders?.pageInfo?.entries || 0) > 10 && (
              <div className="mt-auto md:mt-auto py-4">
                <Paginator
                  count={count}
                  pageInfo={{
                    ...linkPayments.orders.pageInfo,
                    hasPrevPage: count > 1,
                  }}
                  loadMore={loadMore}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="w-full h-full lg:h-[50%] flex justify-center mt-48 max-md:mt-10">
            <div className="flex text-center flex-col items-center">
              <EmptyIcon />
              <p className="my-5 text-[24px] font-[600]">
                It's lonely out here
              </p>
              <p className="w-[175px]">
                All your payments will be displayed here
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
