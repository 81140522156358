import Payment from 'payment';
import React, {useEffect, useMemo, useState} from 'react';
import validator from 'validator';

import {CardInfo} from '..';
import {ReactComponent as LockLogo} from '../../../assets/icons/lock.svg';
import {ReactComponent as MastercardLogo} from '../../../assets/logos/mastercard.svg';
import {ReactComponent as VisaLogo} from '../../../assets/logos/visa.svg';
import InputErrorMessage from '../../../components/common/InputErrorMessage';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  isValidExpirationDate,
} from '../../../utils/helper/validateCreditCard';
import {
  allValuesNonEmpty,
  isAlphabetOnly,
  hasSpecialChars,
  isValidEmail,
} from '../../../utils/helper/validation';
import FormInput from '../../links/Components/FormInput';

const expectedCvvLength = 3;
const expectedCardNumberLength = 16;

export default function CardPay({
  cardData,
  setCardData,
  setIsValidCardData,
}: {
  cardData?: CardInfo;
  setCardData: React.Dispatch<React.SetStateAction<CardInfo>>;
  setIsValidCardData: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [cardNumberError, setCardNumberError] = useState('');
  const [expiryError, setExpiryError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [cvvError, setCvvError] = useState('');
  const [cardIssuer, setCardIssuer] = useState('');

  const validateCvvLength = (value?: string) => {
    if (!value?.length) {
      setCvvError('');
      return;
    }
    const cvvLength = value.length;
    setCvvError(
      cvvLength !== expectedCvvLength
        ? `Must be ${expectedCvvLength} digits`
        : ''
    );
  };

  const validateCreditCard = (number: string, issuer: string) => {
    if (!number.length) {
      setCardNumberError('');
      return;
    }

    if (issuer === 'amex') {
      setCardNumberError('American express is not supported yet');
      return;
    }

    if (number.replaceAll(' ', '').length < expectedCardNumberLength) {
      setCardNumberError('Card number has an invalid length');
      return;
    }

    if (!validator.isCreditCard(number)) {
      setCardNumberError('Please enter a valid card number');
      return;
    }

    setCardData((prev) => ({...prev, number}));
    setCardNumberError('');
    validateCvvLength(cardData?.cvvcode);
  };

  const validateExpiry = (value: string) => {
    if (value && !isValidExpirationDate(value)) {
      setExpiryError('Enter a valid future date');
    } else {
      setExpiryError('');
    }
  };

  const handleInputChange = (field: string, value: string) => {
    switch (field) {
      case 'number': {
        const formattedNumber = formatCreditCardNumber(value);
        const issuer = Payment.fns.cardType(value);
        setCardIssuer(issuer);
        setCardData((prev) => ({...prev, number: formattedNumber}));
        validateCreditCard(value, issuer);
        break;
      }
      case 'expiry': {
        const formattedExpiry = formatExpirationDate(value);
        setCardData((prev) => ({...prev, expiration: formattedExpiry}));
        validateExpiry(value);
        break;
      }
      case 'cvc': {
        const formattedCVC = formatCVC(value);
        setCardData((prev) => ({...prev, cvvcode: formattedCVC}));
        validateCvvLength(value);
        break;
      }
      default:
        break;
    }
  };

  const handleEmailChange = (val: string) => {
    setEmailError('');
    if (val && !isValidEmail(val)) {
      setEmailError('Enter a valid email address');
    }
    setCardData((prev) => ({...prev, email: val}));
  };

  const valid = useMemo(() => {
    if (!cardData) return false;
    return (
      allValuesNonEmpty(cardData) &&
      !cardNumberError &&
      !cvvError &&
      !expiryError &&
      !emailError
    );
  }, [cardData, cardNumberError, expiryError, emailError, cvvError]);

  useEffect(() => {
    setIsValidCardData(!!valid);
  }, [valid, setIsValidCardData]);

  return (
    <div>
      <div className="mt-6 max-md:mb-4">
        <div className="flex justify-between">
          <FormInput
            className="w-[17vw] max-md:w-[44vw]"
            label="First name"
            type="text"
            onChange={(val) => {
              if ((!hasSpecialChars(val) && isAlphabetOnly(val)) || !val) {
                setCardData((prev) => ({...prev, firstName: val}));
              }
            }}
            required
            value={cardData?.firstName?.replaceAll(' ', '')}
          />
          <FormInput
            className="w-[17vw] max-md:w-[44vw]"
            label="Last name"
            onChange={(val) => {
              if ((!hasSpecialChars(val) && isAlphabetOnly(val)) || !val) {
                setCardData((prev) => ({...prev, lastName: val}));
              }
            }}
            required
            value={cardData?.lastName?.replaceAll(' ', '')}
          />
        </div>
      </div>
      <div className="max-md:mb-6">
        <FormInput
          className="w-full"
          label="Email"
          placeholder="user@email.com"
          onChange={handleEmailChange}
          required
          value={cardData?.email?.replaceAll(' ', '')}
          error={<InputErrorMessage errorMessage={emailError} />}
        />
      </div>
      <div className="max-md:mb-6">
        <FormInput
          className="w-full"
          label="Card number"
          placeholder="0000 0000 0000 0000"
          maxLength={19}
          onChange={(val) => handleInputChange('number', val)}
          labelIcon={<LockLogo />}
          onBlur={() => {
            cardData?.cvvcode && validateCvvLength(cardData?.cvvcode);
          }}
          rightElement={
            <div className="flex max-md:gap-2 gap-4 max-md:w-[50px] w-[120px] items-center justify-end min-h-[28px]">
              {(!cardIssuer || cardIssuer === 'visa') && (
                <VisaLogo className="min-w-[28px] h-[28px]" />
              )}
              {(!cardIssuer || cardIssuer === 'mastercard') && (
                <MastercardLogo className="min-w-[28px] h-[28px]" />
              )}
            </div>
          }
          required
          value={cardData?.number}
          error={<InputErrorMessage errorMessage={cardNumberError} />}
        />
      </div>
      <div className="flex lg:justify-between max-md:gap-4 max-md:mb-6">
        <FormInput
          className="w-[17vw] max-md:w-[44vw]"
          label="Expiration"
          placeholder="MM/YY"
          onChange={(val) => handleInputChange('expiry', val)}
          required
          error={<InputErrorMessage errorMessage={expiryError} />}
          value={cardData?.expiration}
        />
        <FormInput
          className="w-[17vw] max-md:w-[44vw]"
          label="Security Code (CVV)"
          placeholder="123"
          onChange={(val) => handleInputChange('cvc', val)}
          required
          value={cardData?.cvvcode || ''}
          error={<InputErrorMessage errorMessage={cvvError} />}
          maxLength={expectedCvvLength}
        />
      </div>
    </div>
  );
}
