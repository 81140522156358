import {Input} from '@cashiaApp/web-components';
import React, {ReactNode} from 'react';

import {cn} from '../../../utils/reusableFunctions';

type InputProps = {
  label?: string;
  name?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  value?: string;
  moreInfo?: string;
  error?: JSX.Element | null;
  required?: boolean;
  rightElement?: ReactNode;
  leftElement?: ReactNode;
  labelIcon?: ReactNode;
  placeholder?: string;
  width?: string;
  type?: string;
  min?: string;
  max?: string;
  className?: string;
  maxLength?: number;
  onBlur?: () => void;
};

export default function FormInput({
  error,
  label,
  onChange,
  disabled,
  value,
  moreInfo,
  required,
  name,
  placeholder,
  rightElement,
  leftElement,
  type,
  min,
  max,
  maxLength,
  labelIcon,
  onBlur,
  className = '',
  width = '90%',
}: InputProps) {
  return (
    <div className="mb-6 max-md:mb-0">
      <p className="flex font-[500] mb-2 gap-1 items-center text-sm leading-[16px] flex-wrap">
        {label} {labelIcon} {required ? '*' : ''}{' '}
      </p>
      {moreInfo && (
        <p className="text-[12px] font-[400] text-foggy">{moreInfo}</p>
      )}
      <Input
        onChange={(e) => onChange(e.target.value)}
        name={name || label}
        value={value}
        placeholder={placeholder}
        type={type}
        min={min}
        disabled={disabled}
        max={max}
        maxLength={maxLength}
        onBlur={onBlur}
        className={cn(
          `pl-2 max-md:w-[92vw] w-[${width}] bg-offWhite border-dividerGrey h-[48px] focus:outline-none focus:ring-0 focus:border-red-300 ${className}`,
          {
            'pl-[55px] mb-[-25px]': leftElement,
            'mb-[-30px]': rightElement,
            'border-red-400 border-[1px]': (
              error?.props as {errorMessage: string}
            )?.errorMessage,
          }
        )}
      />
      {leftElement && (
        <div className="relative top-[-11px] left-3 w-[32px]">
          {leftElement}
        </div>
      )}
      {rightElement && (
        <div className="relative top-[-8px] left-[75%] w-[100px]">
          {rightElement}
        </div>
      )}
      <div>{error ? error : ''}</div>
    </div>
  );
}
