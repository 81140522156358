import React from 'react';

import {getBankLogo} from '../../utils/constants/bankLogos';

type BankLogoProps = {
  bankName?: string;
  className?: string;
};

export const BankLogo: React.FC<BankLogoProps> = ({bankName, className}) => {
  const LogoComponent = getBankLogo(bankName);
  return <LogoComponent className={className} />;
};
