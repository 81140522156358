import {Toaster, useMediaQuery} from '@cashiaApp/web-components';
import {useLayoutEffect} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';

import RequireAuthLayout from './AuthRequired';
import ToBeWorkedOn from '../pages/ToBeWorkedOn';
import Validation from '../pages/auth/EmailValidation';
import AuthComponent from '../pages/auth/Signup';
import BusinessProfileLayout from '../pages/businessProfile/layout';
import BankAccountDetails from '../pages/businessProfile/pages/BankAccountDetails';
import BusinessInformation from '../pages/businessProfile/pages/BusinessInformation';
import BusinessProfileOwnerInfo from '../pages/businessProfile/pages/BusinessOwnerInfo';
import BusinessSocialMedia from '../pages/businessProfile/pages/SocialMedia';
import NotFound from '../pages/errors/NotFound';
import Home from '../pages/home';
import LinksPage from '../pages/links';
import CreateLink from '../pages/links/pages/CreateLink';
import LinkDetails from '../pages/links/pages/LinkDetails';
import Links from '../pages/links/pages/Links';
import Payment from '../pages/payment';
import PaymentStatus from '../pages/payment/pages/paymentStatus';
import PaymentDetails from '../pages/paymentsDisplay/components/PaymentDetails';
import Payments from '../pages/paymentsDisplay/components/Payments';
import Payouts from '../pages/payouts/Payouts';
import BusinessInfo from '../pages/selfOnboarding/components/BusinessInfo';
import BusinessOwnerInfo from '../pages/selfOnboarding/components/BusinessOwnerInfo';
import Success from '../pages/selfOnboarding/components/Success';
import BankInfo from '../pages/selfOnboarding/components/bankinfo';
import SocialMedia from '../pages/selfOnboarding/components/socialMedia';
import SelfOnboardingLayout from '../pages/selfOnboarding/layout';
import Terms from '../pages/terms/terms';

type RouteTitles = {
  [key: string]: string;
};

const routeTitles: RouteTitles = {
  '/': 'Cashia',
  '/signup': 'Signup',
  '/login': 'Login to your account',
  '/home': 'Dashboard',
  '/home/self-onboarding': 'Self Onboarding',
  '/home/self-onboarding/business-info': 'Business Information',
  '/home/self-onboarding/business-owner-info': 'Business Owner Details',
  '/home/self-onboarding/bank-info': 'Bank Account Details',
  '/home/self-onboarding/social-media': 'Social Media Details',
  '/home/success': 'Business Profile Complete',
  '/payment-links': 'Payment links',
  '/payments': 'Payments',
  '/payouts': 'Payouts',
  '/business-profile': 'Business Profile',
  '/settings': 'Settings',
};

const getNestedTitle = (path: string): string => {
  const pathParts = path.split('/').filter(Boolean);
  let currentPath = '';
  let lastValidTitle = 'Cashia';

  for (let i = pathParts.length; i > 0; i--) {
    currentPath = '/' + pathParts.slice(0, i).join('/');
    if (currentPath in routeTitles) {
      lastValidTitle = routeTitles[currentPath] ?? 'Cashia';
      break;
    }
  }

  return lastValidTitle === 'Cashia' ? 'Cashia' : `${lastValidTitle} | Cashia`;
};

const AppRoutes: React.FC = () => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const location = useLocation();

  useLayoutEffect(() => {
    document.title = getNestedTitle(location.pathname);
  }, [location]);

  return (
    <>
      <Toaster
        richColors
        toastOptions={{}}
        position={isDesktop ? 'bottom-left' : 'top-right'}
      />
      <Routes>
        <Route path="/terms" element={<Terms />} />
        <Route index element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<AuthComponent />} />
        <Route path="/signup" element={<AuthComponent />} />
        <Route path="/email-verification" element={<Validation />} />
        <Route path="*" element={<NotFound />} />
        <Route
          index
          path="/payment-links/create-link"
          element={<CreateLink />}
        />
        <Route index path="/payment-links/edit-link" element={<CreateLink />} />

        <Route
          index
          path="/link/:businessName/:reference"
          element={<Payment />}
        />
        <Route
          index
          path="/link/:businessName/:reference/status"
          element={<PaymentStatus />}
        />
        <Route
          index
          path="/link/:businessName/:reference/card/status"
          element={<PaymentStatus method="card" />}
        />
        <Route
          index
          path="/link/:businessName/:reference/card"
          element={<Payment method="card" />}
        />
        <Route path="/" element={<RequireAuthLayout />}>
          <Route path="home">
            <Route path="success" element={<Success />} />
            <Route index element={<Home />} />
            <Route path="self-onboarding" element={<SelfOnboardingLayout />}>
              <Route path="business-info" element={<BusinessInfo />} />
              <Route path="bank-info" element={<BankInfo />} />
              <Route path="social-media" element={<SocialMedia />} />
              <Route
                path="business-owner-info"
                element={<BusinessOwnerInfo />}
              />
            </Route>
          </Route>
          <Route path="payment-links" element={<LinksPage />}>
            <Route index path="" element={<Links status="all" />} />
            <Route path="link-details" element={<LinkDetails />} />
            <Route path="links/active" element={<Links status="Active" />} />
            <Route
              path="links/deactivated"
              element={<Links status="Deactivated" />}
            />
          </Route>
          <Route path="all-payments">
            <Route index element={<Payments />} />
            <Route
              path="payments/successful"
              element={<Payments status="successful" />}
            />
            <Route
              path="payments/pending"
              element={<Payments status="pending" />}
            />
            <Route
              path="payments/failed"
              element={<Payments status="failed" />}
            />
            <Route
              path="payments/details/:paymentId"
              element={<PaymentDetails />}
            />
          </Route>

          <Route path="payouts" element={<Payouts />} />
          <Route path="settings" element={<ToBeWorkedOn />} />
          <Route path="business-profile" element={<BusinessProfileLayout />}>
            <Route index element={<BusinessInformation />} />
            <Route path="owner-info" element={<BusinessProfileOwnerInfo />} />
            <Route path="bank-details" element={<BankAccountDetails />} />
            <Route path="social-media" element={<BusinessSocialMedia />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
