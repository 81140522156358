import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  split,
  Operation,
  NextLink,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {GraphQLWsLink} from '@apollo/client/link/subscriptions';
import {getMainDefinition} from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import {GraphQLFormattedError} from 'graphql';
import {createClient} from 'graphql-ws';
import React, {PropsWithChildren, useMemo} from 'react';

import typePolicies from './apollo/typePolicies';
import {useAuth, promiseToObservable} from './auth';
import {localStorageAccessTokenKey, apiUrl, apiUrlSocket} from './constants';
import resolvers from './mocks/resolvers';
import typeDefs from './mocks/typeDefs';
import {ErrorTypes} from '../graphql/generated';
import result from '../graphql/introspection-result';

interface Props {
  terminatingLink?: ApolloLink;
}

const httpLink = createUploadLink({uri: apiUrl});

const GraphQLProvider = ({
  children,
  terminatingLink = httpLink,
}: PropsWithChildren<Props>) => {
  const {getNewTokens, logout} = useAuth();
  const token = localStorage.getItem(localStorageAccessTokenKey) || '';

  const authMiddleware = setContext(() => {
    return {
      headers: {
        ...(token ? {authorization: `Bearer ${token}`} : {}),
      },
    };
  });

  const errorLink = onError(
    ({
      graphQLErrors,
      operation,
      forward,
    }: {
      graphQLErrors?: readonly GraphQLFormattedError[];
      operation: Operation;
      forward: NextLink;
    }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          const errorType = err.extensions?.['type'] as ErrorTypes;
          switch (errorType) {
            case ErrorTypes.TokenExpired:
              return promiseToObservable(getNewTokens()).flatMap(() =>
                forward(operation)
              );
            case ErrorTypes.TokenInvalid:
              void logout();
              break;
          }
        }
      }
    }
  );

  const wsLink = useMemo(() => {
    return new GraphQLWsLink(
      createClient({
        url: apiUrlSocket,
        connectionParams: (): {Authorization: string} => ({
          Authorization: `Bearer ${token}`,
        }),
        on: {
          connecting: () => console.warn('connecting'),
          connected: () => console.warn('connected'),
          error: (err: unknown) => {
            if (err instanceof Error) {
              console.error(err.message);
            } else {
              console.error('Unknown error', err);
            }
          },
        },
      })
    );
  }, [token]);

  const splitLink = useMemo(
    () =>
      split(
        ({query}) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        terminatingLink
      ),
    [terminatingLink, wsLink]
  );

  const client = new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: result.possibleTypes,
      typePolicies,
    }),
    link: errorLink.concat(authMiddleware).concat(splitLink),
    resolvers,
    typeDefs,
    connectToDevTools: process.env.NODE_ENV === 'development',
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQLProvider;
